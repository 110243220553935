import { BackButton, MainButton, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Image, Input, Progress, Row, Spin, Tabs, Typography } from "antd";
import type { TabsProps } from 'antd';
import { useEffect, useState } from "react";
import { QuestionNoAnswer, QuestionPart, ShortTour } from "../../generated/openapi/api";
import api from "../../api";
import { SmileOutlined } from "@ant-design/icons";
import { GameScreenCommonProps } from "./GameCommons";
import { start } from "repl";

const secDefaultLeft = 60
const inputExtraTime = 10

const QuestionPartScreen = (props: {
  part: QuestionPart,
  onChange: (input: string) => any,
  secLeft: number
}) => {
  const { part, onChange, secLeft } = props

  let progress
  if (secLeft > inputExtraTime) {
    progress = <Progress
      type="circle"
      percent={(secLeft - inputExtraTime) / secDefaultLeft * 100}
      size={20}
      format={() => { return secLeft }}
    // showInfo={false}
    />
  } else {
    progress = <Typography.Text style={{ minWidth: "2em" }}>{secLeft}</Typography.Text>
  }

  return <>
    {part.image && <Row><Image src={part.image} /></Row>}
    <Row>
      <Typography.Paragraph>{part.text}</Typography.Paragraph>
    </Row>
    <Row>
      <Input
        placeholder="Ответ"
        allowClear={true}
        onChange={(event) => { onChange(event.target.value) }}
        addonAfter={progress}
      />
    </Row>
  </>
}

export const GameActiveQuestionScreen = (props: GameScreenCommonProps) => {
  const { gameState, goNext } = props;
  const [question, setQuestion] = useState<QuestionNoAnswer>();
  const [answers, setAnswers] = useState<string[]>([]);
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);
  const [secLeft, setSecLeft] = useState<number>(secDefaultLeft + inputExtraTime)
  const showPopup = useShowPopup();

  useEffect(() => {
    api.gameGetQuestion(gameState.id).then((response) => {
      setAnswers(response.data.question.parts_questions.map(() => { return "" }))
      setQuestion(response.data.question);
    }).catch(() => {
      showPopup({ message: 'Ошибка при загрузке информации о туре' });
    });

    const interval = setInterval(() => {
      if (gameState.current_start == null) {
        return
      }
      const secFromStart = Math.round((Date.now() - Date.parse(gameState.current_start)) / 1000)
      const nextSecLeft = Math.max(0, secDefaultLeft + inputExtraTime - secFromStart)
      setSecLeft(nextSecLeft)

      if (nextSecLeft == 0) {
        goNext()
      }
    }, 1000)
    return () => clearInterval(interval);
  }, []);

  const updateAnswer = (partId: number, answer: string) => {
    let nextAnswers = answers.slice()
    nextAnswers[partId] = answer
    setAnswers(nextAnswers)

    api.gameSubmitAnswer(gameState.id, { answers: nextAnswers })
      .catch((reason) => {
        console.log('Ошибка при отправке ответа', reason);
      })

  }

  const sumbitAnswer = () => {
    if (confirmSubmit) {
      goNext()
      return
    }

    setTimeout(() => {
      setConfirmSubmit(false)
    }, 3000)

    setConfirmSubmit(true)
  }

  if (question == null) {
    return <Row justify="center"><Spin /></Row>;
  }

  // Do not render tabs if only one part present
  const tabsItems: TabsProps['items'] = question.parts_questions.map((part, index) => {
    const id = index
    return {
      key: String(id),
      label: `Часть ${index + 1}`,
      children: <QuestionPartScreen
        part={part}
        onChange={(answer) => updateAnswer(id, answer)}
        secLeft={secLeft}
      />
    }
  })
  const questionContent = <Tabs items={tabsItems} />

  return <>
    <Row justify="center">
      <Typography.Title level={3}>Вопрос {gameState.current_question + 1}</Typography.Title>
    </Row>

    <Row justify="center">
      <Typography.Text type="secondary">
        <SmileOutlined /> {question.authors.length > 0 ? question.authors.join(", ") : "Авторы не указаны"}
      </Typography.Text>
    </Row>

    <Row>
      {questionContent}
    </Row>

    <BackButton onClick={() => { }} />
    <MainButton onClick={() => { sumbitAnswer(); }}
      text={confirmSubmit ? `Подтвердить` : "Досрочный ответ"}
      color={confirmSubmit ? "#fa541c" : undefined}
      textColor={confirmSubmit ? "#ffffff" : undefined}
    />
  </>;
};
