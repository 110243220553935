/* tslint:disable */
/* eslint-disable */
/**
 * Savor backend API
 * This is a public API of savor
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnswerPart
 */
export interface AnswerPart {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnswerPart
     */
    'answers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnswerPart
     */
    'explanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerPart
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerPart
     */
    'type': AnswerPartTypeEnum;
}

export const AnswerPartTypeEnum = {
    Levenshtein: 'levenshtein',
    Regex: 'regex'
} as const;

export type AnswerPartTypeEnum = typeof AnswerPartTypeEnum[keyof typeof AnswerPartTypeEnum];

/**
 * 
 * @export
 * @interface GameGetAnswerResponse
 */
export interface GameGetAnswerResponse {
    /**
     * 
     * @type {Array<RestGameAnswer>}
     * @memberof GameGetAnswerResponse
     */
    'answer_parts': Array<RestGameAnswer>;
    /**
     * 
     * @type {string}
     * @memberof GameGetAnswerResponse
     */
    'composite_status': GameGetAnswerResponseCompositeStatusEnum;
}

export const GameGetAnswerResponseCompositeStatusEnum = {
    Wrong: 'wrong',
    Correct: 'correct'
} as const;

export type GameGetAnswerResponseCompositeStatusEnum = typeof GameGetAnswerResponseCompositeStatusEnum[keyof typeof GameGetAnswerResponseCompositeStatusEnum];

/**
 * 
 * @export
 * @interface GameGetQuestionResponse
 */
export interface GameGetQuestionResponse {
    /**
     * 
     * @type {QuestionNoAnswer}
     * @memberof GameGetQuestionResponse
     */
    'question': QuestionNoAnswer;
}
/**
 * 
 * @export
 * @interface GameGetResponse
 */
export interface GameGetResponse {
    /**
     * 
     * @type {RestGameState}
     * @memberof GameGetResponse
     */
    'game': RestGameState;
}
/**
 * 
 * @export
 * @interface GameNewRequest
 */
export interface GameNewRequest {
    /**
     * 
     * @type {string}
     * @memberof GameNewRequest
     */
    'pack_id': string;
}
/**
 * 
 * @export
 * @interface GameNewResponse
 */
export interface GameNewResponse {
    /**
     * 
     * @type {RestGameState}
     * @memberof GameNewResponse
     */
    'game_state': RestGameState;
}
/**
 * 
 * @export
 * @interface GameNextRespones
 */
export interface GameNextRespones {
    /**
     * 
     * @type {RestGameState}
     * @memberof GameNextRespones
     */
    'game_state': RestGameState;
}
/**
 * 
 * @export
 * @interface GameSubmitAnswerRequest
 */
export interface GameSubmitAnswerRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GameSubmitAnswerRequest
     */
    'answers': Array<string>;
}
/**
 * 
 * @export
 * @interface GameTourInfo
 */
export interface GameTourInfo {
    /**
     * 
     * @type {ShortTour}
     * @memberof GameTourInfo
     */
    'tour': ShortTour;
}
/**
 * 
 * @export
 * @interface GamesGetActiveResponse
 */
export interface GamesGetActiveResponse {
    /**
     * 
     * @type {Array<RestShortPackAndGame>}
     * @memberof GamesGetActiveResponse
     */
    'games': Array<RestShortPackAndGame>;
}
/**
 * 
 * @export
 * @interface GetPackResponse
 */
export interface GetPackResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPackResponse
     */
    'id': string;
    /**
     * 
     * @type {PackDescription}
     * @memberof GetPackResponse
     */
    'pack': PackDescription;
}
/**
 * 
 * @export
 * @interface HealthCheckResponse
 */
export interface HealthCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof HealthCheckResponse
     */
    'ok': boolean;
}
/**
 * 
 * @export
 * @interface ImportPackRequest
 */
export interface ImportPackRequest {
    /**
     * 
     * @type {Pack}
     * @memberof ImportPackRequest
     */
    'pack': Pack;
}
/**
 * 
 * @export
 * @interface ImportPackResponse
 */
export interface ImportPackResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportPackResponse
     */
    'pack_id': string;
}
/**
 * 
 * @export
 * @interface Pack
 */
export interface Pack {
    /**
     * 
     * @type {Array<string>}
     * @memberof Pack
     */
    'authors': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Pack
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pack
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pack
     */
    'source'?: string;
    /**
     * 
     * @type {Array<TourBase>}
     * @memberof Pack
     */
    'tours': Array<TourBase>;
    /**
     * 
     * @type {string}
     * @memberof Pack
     */
    'type': PackTypeEnum;
}

export const PackTypeEnum = {
    Chgk: 'chgk'
} as const;

export type PackTypeEnum = typeof PackTypeEnum[keyof typeof PackTypeEnum];

/**
 * 
 * @export
 * @interface PackDescription
 */
export interface PackDescription {
    /**
     * 
     * @type {Array<string>}
     * @memberof PackDescription
     */
    'authors': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PackDescription
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackDescription
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PackDescription
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PackDescription
     */
    'number_of_questions': number;
    /**
     * 
     * @type {number}
     * @memberof PackDescription
     */
    'number_of_tours': number;
    /**
     * 
     * @type {string}
     * @memberof PackDescription
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackDescription
     */
    'type': PackDescriptionTypeEnum;
}

export const PackDescriptionTypeEnum = {
    Chgk: 'chgk'
} as const;

export type PackDescriptionTypeEnum = typeof PackDescriptionTypeEnum[keyof typeof PackDescriptionTypeEnum];

/**
 * 
 * @export
 * @interface PacksListResponse
 */
export interface PacksListResponse {
    /**
     * 
     * @type {Array<ShortPack>}
     * @memberof PacksListResponse
     */
    'packs': Array<ShortPack>;
}
/**
 * 
 * @export
 * @interface Part
 */
export interface Part {
    /**
     * 
     * @type {AnswerPart}
     * @memberof Part
     */
    'answer': AnswerPart;
    /**
     * 
     * @type {QuestionPart}
     * @memberof Part
     */
    'question': QuestionPart;
}
/**
 * 
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     * 
     * @type {StructsSavorUser}
     * @memberof PingResponse
     */
    'user': StructsSavorUser;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {Array<string>}
     * @memberof Question
     */
    'authors': Array<string>;
    /**
     * 
     * @type {Array<Part>}
     * @memberof Question
     */
    'parts': Array<Part>;
}
/**
 * 
 * @export
 * @interface QuestionNoAnswer
 */
export interface QuestionNoAnswer {
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionNoAnswer
     */
    'authors': Array<string>;
    /**
     * 
     * @type {Array<QuestionPart>}
     * @memberof QuestionNoAnswer
     */
    'parts_questions': Array<QuestionPart>;
}
/**
 * 
 * @export
 * @interface QuestionPart
 */
export interface QuestionPart {
    /**
     * 
     * @type {string}
     * @memberof QuestionPart
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionPart
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface RestGameAnswer
 */
export interface RestGameAnswer {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestGameAnswer
     */
    'correct_answers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RestGameAnswer
     */
    'explanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestGameAnswer
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestGameAnswer
     */
    'playerAnswer': string;
    /**
     * 
     * @type {string}
     * @memberof RestGameAnswer
     */
    'verdict': RestGameAnswerVerdictEnum;
}

export const RestGameAnswerVerdictEnum = {
    Wrong: 'wrong',
    Correct: 'correct'
} as const;

export type RestGameAnswerVerdictEnum = typeof RestGameAnswerVerdictEnum[keyof typeof RestGameAnswerVerdictEnum];

/**
 * 
 * @export
 * @interface RestGameState
 */
export interface RestGameState {
    /**
     * 
     * @type {string}
     * @memberof RestGameState
     */
    'current_phase': RestGameStateCurrentPhaseEnum;
    /**
     * 
     * @type {number}
     * @memberof RestGameState
     */
    'current_question': number;
    /**
     * 
     * @type {string}
     * @memberof RestGameState
     */
    'current_start'?: string;
    /**
     * 
     * @type {number}
     * @memberof RestGameState
     */
    'current_tour': number;
    /**
     * 
     * @type {string}
     * @memberof RestGameState
     */
    'frontendState'?: string;
    /**
     * 
     * @type {string}
     * @memberof RestGameState
     */
    'id': string;
}

export const RestGameStateCurrentPhaseEnum = {
    TourInfo: 'tour_info',
    ActiveQuestion: 'active_question',
    QuestionResults: 'question_results',
    Finished: 'finished'
} as const;

export type RestGameStateCurrentPhaseEnum = typeof RestGameStateCurrentPhaseEnum[keyof typeof RestGameStateCurrentPhaseEnum];

/**
 * 
 * @export
 * @interface RestShortPackAndGame
 */
export interface RestShortPackAndGame {
    /**
     * 
     * @type {string}
     * @memberof RestShortPackAndGame
     */
    'game_id': string;
    /**
     * 
     * @type {string}
     * @memberof RestShortPackAndGame
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RestShortPackAndGame
     */
    'pack_id': string;
    /**
     * 
     * @type {string}
     * @memberof RestShortPackAndGame
     */
    'pack_type': string;
}
/**
 * 
 * @export
 * @interface ShortPack
 */
export interface ShortPack {
    /**
     * 
     * @type {string}
     * @memberof ShortPack
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ShortPack
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShortPack
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ShortTour
 */
export interface ShortTour {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShortTour
     */
    'authors': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShortTour
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTour
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ShortTour
     */
    'number_of_questions': number;
}
/**
 * 
 * @export
 * @interface StructsSavorUser
 */
export interface StructsSavorUser {
    /**
     * 
     * @type {boolean}
     * @memberof StructsSavorUser
     */
    'allows_write_to_pm': boolean;
    /**
     * 
     * @type {string}
     * @memberof StructsSavorUser
     */
    'first_name': string;
    /**
     * 
     * @type {number}
     * @memberof StructsSavorUser
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StructsSavorUser
     */
    'language_code': string;
    /**
     * 
     * @type {string}
     * @memberof StructsSavorUser
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof StructsSavorUser
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof StructsSavorUser
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface TourBase
 */
export interface TourBase {
    /**
     * 
     * @type {Array<string>}
     * @memberof TourBase
     */
    'authors': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TourBase
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TourBase
     */
    'name': string;
    /**
     * 
     * @type {Array<Question>}
     * @memberof TourBase
     */
    'questions': Array<Question>;
}

/**
 * PrivateApi - axios parameter creator
 * @export
 */
export const PrivateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Import new pack
         * @param {ImportPackRequest} request pack object to import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPack: async (request: ImportPackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('importPack', 'request', request)
            const localVarPath = `/private/import-pack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateApi - functional programming interface
 * @export
 */
export const PrivateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Import new pack
         * @param {ImportPackRequest} request pack object to import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPack(request: ImportPackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPack(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateApi - factory interface
 * @export
 */
export const PrivateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateApiFp(configuration)
    return {
        /**
         * 
         * @summary Import new pack
         * @param {ImportPackRequest} request pack object to import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPack(request: ImportPackRequest, options?: any): AxiosPromise<ImportPackResponse> {
            return localVarFp.importPack(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrivateApi - object-oriented interface
 * @export
 * @class PrivateApi
 * @extends {BaseAPI}
 */
export class PrivateApi extends BaseAPI {
    /**
     * 
     * @summary Import new pack
     * @param {ImportPackRequest} request pack object to import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateApi
     */
    public importPack(request: ImportPackRequest, options?: AxiosRequestConfig) {
        return PrivateApiFp(this.configuration).importPack(request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns actual game state by game id
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameGet', 'id', id)
            const localVarPath = `/v1/game/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of active games
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetActive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/games/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetAnswer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameGetAnswer', 'id', id)
            const localVarPath = `/v1/game/{id}/answer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetQuestion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameGetQuestion', 'id', id)
            const localVarPath = `/v1/game/{id}/question`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns actual tour info of game
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetTour: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameGetTour', 'id', id)
            const localVarPath = `/v1/game/{id}/tour`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initializes new game
         * @param {GameNewRequest} request New game request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameNew: async (request: GameNewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('gameNew', 'request', request)
            const localVarPath = `/v1/game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move game to next phase
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameNext: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameNext', 'id', id)
            const localVarPath = `/v1/game/{id}/next`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sumbxt answer
         * @param {string} id Game ID
         * @param {GameSubmitAnswerRequest} request Player answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameSubmitAnswer: async (id: string, request: GameSubmitAnswerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gameSubmitAnswer', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('gameSubmitAnswer', 'request', request)
            const localVarPath = `/v1/game/{id}/answer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get full pack info
         * @param {string} id Pack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPack: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPack', 'id', id)
            const localVarPath = `/v1/pack/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPacks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/packs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pings server with authorization for test purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InitDataAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Init-Data", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns actual game state by game id
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of active games
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameGetActive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GamesGetActiveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameGetActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameGetAnswer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameGetAnswerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameGetAnswer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameGetQuestion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameGetQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameGetQuestion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns actual tour info of game
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameGetTour(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameTourInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameGetTour(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initializes new game
         * @param {GameNewRequest} request New game request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameNew(request: GameNewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameNewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameNew(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move game to next phase
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameNext(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameNextRespones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameNext(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sumbxt answer
         * @param {string} id Game ID
         * @param {GameSubmitAnswerRequest} request Player answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gameSubmitAnswer(id: string, request: GameSubmitAnswerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gameSubmitAnswer(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get full pack info
         * @param {string} id Pack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPack(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPack(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List available packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPacks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacksListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPacks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pings server with authorization for test purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns actual game state by game id
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGet(id: string, options?: any): AxiosPromise<GameGetResponse> {
            return localVarFp.gameGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of active games
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetActive(options?: any): AxiosPromise<GamesGetActiveResponse> {
            return localVarFp.gameGetActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetAnswer(id: string, options?: any): AxiosPromise<GameGetAnswerResponse> {
            return localVarFp.gameGetAnswer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return question for current state or error
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetQuestion(id: string, options?: any): AxiosPromise<GameGetQuestionResponse> {
            return localVarFp.gameGetQuestion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns actual tour info of game
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameGetTour(id: string, options?: any): AxiosPromise<GameTourInfo> {
            return localVarFp.gameGetTour(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initializes new game
         * @param {GameNewRequest} request New game request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameNew(request: GameNewRequest, options?: any): AxiosPromise<GameNewResponse> {
            return localVarFp.gameNew(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move game to next phase
         * @param {string} id Game ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameNext(id: string, options?: any): AxiosPromise<GameNextRespones> {
            return localVarFp.gameNext(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sumbxt answer
         * @param {string} id Game ID
         * @param {GameSubmitAnswerRequest} request Player answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gameSubmitAnswer(id: string, request: GameSubmitAnswerRequest, options?: any): AxiosPromise<object> {
            return localVarFp.gameSubmitAnswer(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get full pack info
         * @param {string} id Pack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPack(id: string, options?: any): AxiosPromise<GetPackResponse> {
            return localVarFp.getPack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List available packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPacks(options?: any): AxiosPromise<PacksListResponse> {
            return localVarFp.listPacks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pings server with authorization for test purposes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Returns actual game state by game id
     * @param {string} id Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameGet(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of active games
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameGetActive(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameGetActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return question for current state or error
     * @param {string} id Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameGetAnswer(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameGetAnswer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return question for current state or error
     * @param {string} id Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameGetQuestion(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameGetQuestion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns actual tour info of game
     * @param {string} id Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameGetTour(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameGetTour(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initializes new game
     * @param {GameNewRequest} request New game request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameNew(request: GameNewRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameNew(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move game to next phase
     * @param {string} id Game ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameNext(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameNext(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sumbxt answer
     * @param {string} id Game ID
     * @param {GameSubmitAnswerRequest} request Player answer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public gameSubmitAnswer(id: string, request: GameSubmitAnswerRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).gameSubmitAnswer(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get full pack info
     * @param {string} id Pack ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPack(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List available packs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public listPacks(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).listPacks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pings server with authorization for test purposes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public ping(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks health of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checks health of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Checks health of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<HealthCheckResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Checks health of system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public health(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}



