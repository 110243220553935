import { BackButton, MainButton, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Image, Input, Progress, Row, Space, Spin, Tabs, Typography } from "antd";
import type { TabsProps } from 'antd';
import { useEffect, useState } from "react";
import { AnswerPart, GameGetAnswerResponseCompositeStatusEnum, QuestionNoAnswer, QuestionPart, RestGameAnswer, ShortTour } from "../../generated/openapi/api";
import api from "../../api";
import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { GameScreenCommonProps } from "./GameCommons";
import { start } from "repl";

const secDefaultLeft = 60
const inputExtraTime = 10

const QuestionPartResultScreen = (props: {
  answer: RestGameAnswer,
}) => {
  const { answer } = props

  return <>
    <Row>
      <Typography.Paragraph>
        <Space>
          <span>Ваш ответ: {answer.playerAnswer}</span>
          {answer.verdict === GameGetAnswerResponseCompositeStatusEnum.Correct ?
            <CheckCircleOutlined style={{ color: "#389e0d" }} /> :
            <CloseCircleOutlined style={{ color: "#a8071a" }} />}
        </Space>
        <br />
        Правильный ответ: {answer.correct_answers.join(", ")}
      </Typography.Paragraph>
    </Row>
    {answer.explanation && <>
      <Row>
        <Typography.Title level={5}>Пояснение</Typography.Title>
      </Row>
      <Row>
        {answer.image && <Image src={answer.image} />}
        <Typography.Paragraph>
          {answer.explanation}
        </Typography.Paragraph>
      </Row>
    </>}
  </>
}

export const GameQuestionResultsScreen = (props: GameScreenCommonProps) => {
  const { gameState, goNext } = props;
  const [results, setResults] = useState<RestGameAnswer[]>();
  const [verdict, setVerdict] = useState<GameGetAnswerResponseCompositeStatusEnum>();
  const showPopup = useShowPopup();

  useEffect(() => {
    api.gameGetAnswer(gameState.id).then((response) => {
      setResults(response.data.answer_parts);
      setVerdict(response.data.composite_status);
    }).catch(() => {
      showPopup({ message: 'Ошибка при загрузке информации о туре' });
    });
  }, []);

  if (results == null || verdict == null) {
    return <Row justify="center"><Spin /></Row>;
  }

  // Do not render tabs if only one part present
  const tabsItems: TabsProps['items'] = results.map((result, index) => {
    const id = index
    return {
      key: String(id),
      label: `Часть ${index + 1}`,
      children: <QuestionPartResultScreen
        answer={result}
      />
    }
  })
  const questionContent = <Tabs items={tabsItems} />

  return <>
    <Row justify="center">
      <Typography.Title level={3}>
        <Space>
          <span>Результаты вопроса {gameState.current_question + 1}</span>
          {verdict === GameGetAnswerResponseCompositeStatusEnum.Correct ?
            <CheckCircleOutlined style={{ color: "#389e0d" }} /> :
            <CloseCircleOutlined style={{ color: "#a8071a" }} />}
        </Space>
      </Typography.Title>
    </Row>

    <Row>
      {questionContent}
    </Row>

    <BackButton onClick={() => { }} />
    <MainButton onClick={() => { goNext(); }} text="Дальше" />
  </>;
};
