import { BrowserRouter } from "react-router-dom";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

import { ConfigProvider, theme } from "antd";
import "antd/dist/reset.css";

import "./App.css";

import api from "./api";
import Router from "./pages/router";
import { useEffect } from "react";

const App = () => {
  const [colorScheme, themeParams] = useThemeParams();

  useEffect(() => {
    api
      .ping()
      .then(() => {
        console.log("Authorization passed");
      })
      .catch(() => {
        console.log("Authorization failed");
      });
  }, []);

  return (
    <ConfigProvider
      theme={
        themeParams.text_color
          ? {
              algorithm:
                colorScheme === "dark"
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
              token: {
                colorText: themeParams.text_color,
                colorPrimary: themeParams.button_color,
                colorBgBase: themeParams.bg_color,
              },
            }
          : undefined
      }
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
