import { Typography, List, Space } from "antd";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import api from "../../api";
import { packTypeToIcon } from "../../utils/formats";
import { useEffect, useState } from "react";
import { ShortPack, RestShortPackAndGame } from "../../generated/openapi";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [packsLoaded, setPacksLoaded] = useState<boolean>(false)
  const [packsList, setPacksList] = useState<Array<ShortPack>>([])
  const [activeGamesListLoaded, setActiveGamesListLoaded] = useState<boolean>(false)
  const [activeGamesList, setActiveGamesList] = useState<Array<RestShortPackAndGame>>([])

  const updatePacksList = () => {
    setPacksLoaded(false)
    api.listPacks().then((resp) => {
      setPacksList(resp.data.packs)
    }).finally(() => {
      setPacksLoaded(true)
    })
  }
  const fetchActiveGamesList = () => {
    setPacksLoaded(false)
    api.gameGetActive().then((resp) => {
      setActiveGamesList(resp.data.games)
    }).finally(() => {
      setActiveGamesListLoaded(true)
    })
  }

  useEffect(() => {
    if (!packsLoaded) {
      updatePacksList()
    }
    if (!activeGamesListLoaded) {
      fetchActiveGamesList()
    }
  }, []);

  const navigate = useNavigate()

  const [, notificationOccurred,] =
    useHapticFeedback();

  const openPackDescription = (id: string) => {
    notificationOccurred("success")
    navigate("/pack/" + id)
  }

  const openGame = (id: string) => {
    notificationOccurred("success")
    navigate("/game/" + id)
  }

  let activeGames = null
  if (activeGamesList.length > 0) {
    activeGames = <>
      <Typography.Title level={4}>
        Активные игры
      </Typography.Title>
      <List
        size="small"
        bordered
        dataSource={activeGamesList}
        renderItem={(game) => {
          return <List.Item
            onClick={() => openGame(game.game_id)}
          >
            {packTypeToIcon(game.pack_type)} {game.name}
          </List.Item>
        }}
      />
    </>
  }

  return (
    <>
        {activeGames}
        <Typography.Title level={4}>
          Пакетики!
        </Typography.Title>
        <List
          size="small"
          bordered
          loading={!packsLoaded}
          loadMore={true}
          dataSource={packsList}
          renderItem={(pack) => {
            return <List.Item
              onClick={() => openPackDescription(pack.id)}
            >
              {packTypeToIcon(pack.type)} {pack.name}
            </List.Item>
          }}
        />
    </>
  );
};

export default Home;
