import { SmileOutlined } from "@ant-design/icons";
import { BackButton, MainButton, useExpand, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Typography, Row, Spin, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { PackDescription, GameNewRequest } from "../../generated/openapi";

const { Text, Title, Paragraph } = Typography

const PackDescriptionPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isExpand, expand] = useExpand()
  const [pack, setPack] = useState<PackDescription>()
  const showPopup = useShowPopup()

  const startGame = () => {
    if (pack == null) {
      return
    }

    api.gameNew({pack_id: pack.id}).then((response) => {
      navigate(`/game/${response.data.game_state.id}`)
    }).catch(() => {
      showPopup({ message: 'Ошибка при попытке начать новую игру' }).then(buttonId => console.log(buttonId));
    })
  }

  useEffect(() => {
    if (!isExpand) {
      expand()
    }
  }, [])

  useEffect(() => {
    api.getPack(id!!).then((response) => {
      setPack(response.data.pack)
    }).catch(() => {
      showPopup({ message: 'Ошибка при загрузке пакета' }).then(buttonId => console.log(buttonId));
    })
  }, [])

  if (pack == null){
    return <Row justify="center"><Spin/></Row>
  }
    
  return (
    <>
      <Row justify="center">
        <Title level={3}>
          {pack.name}
        </Title>
      </Row>
      <Row justify="center">
        <Text type="secondary">
          <SmileOutlined/> {pack.authors.length > 0 ? pack.authors.join(", ") : "Авторы не указаны"}
        </Text>
      </Row>
      <Row>
        <Space direction="vertical" size="small">
          <Title level={5}>Описание</Title>
          <Paragraph>
              Туров: <Text type="secondary">{pack.number_of_tours}</Text>
              <br/>
              Всего вопросов: <Text type="secondary">{pack.number_of_questions}</Text> 
          </Paragraph>
          <Paragraph>
              {pack.description}
          </Paragraph>
        </Space>
      </Row>

      <BackButton onClick={() => {navigate(-1)}}/>
      <MainButton onClick={startGame} text="Играть!"/>
    </>
  );
};

export default PackDescriptionPage;
