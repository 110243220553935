import { BackButton, MainButton, useExpand, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestGameState } from "../../generated/openapi/api";
import api from "../../api";
import { GameTourInfoScreen } from "./GameTourInfoScreen";
import { GameActiveQuestionScreen } from "./GameActiveQuestionScreen";
import { GameQuestionResultsScreen } from "./GameQuestionresultsScreen";

const Game = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [isExpand, expand] = useExpand()
  const [gameState, setGameState] = useState<RestGameState>()
  const showPopup = useShowPopup()


  useEffect(() => {
    if (!isExpand) {
      expand()
    }
  }, [])

  const goToNextState = () => {
    api.gameNext(id!!).then((response) => {
      setGameState(response.data.game_state)
    }).catch(() => {
      showPopup({ message: 'Ошибка при переходе игры в новое состояние' })
    })
  }

  useEffect(() => {
    if (gameState == null) {
      api.gameGet(id!!).then((response) => {
        setGameState(response.data.game)
      }).catch(() => {
        showPopup({ message: 'Ошибка при загрузке игры' });
      })
    }
  }, [])

  if (gameState == null) {
    return <Row justify="center"><Spin /></Row>
  }


  switch (gameState.current_phase) {
    case "tour_info": {
      return <GameTourInfoScreen gameState={gameState} goNext={goToNextState} />
    }
    case "active_question": {
      return <GameActiveQuestionScreen gameState={gameState} goNext={goToNextState} />
    }
    case "question_results": {
      return <GameQuestionResultsScreen gameState={gameState} goNext={goToNextState} />
    }
    case "finished": {
      return <>
        <Typography.Paragraph >Игра окончена! Поздравлямба!</Typography.Paragraph>
        <BackButton onClick={() => { navigate("/") }} />
        <MainButton onClick={() => { navigate("/") }} text="Домой" />
      </>
    }
  }
};

export default Game;
