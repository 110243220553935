import { routerType } from "../types/router.types";
import Home from "./Home";
import PackDescriptionPage from "./PackDescription";
import Game from "./Game";

const pagesData: routerType[] = [
  {
    path: "",
    element: <Home />,
    title: "home",
  },
  {
    path: "/pack/:id",
    element: <PackDescriptionPage/>,
    title: "pack description",
  },
  {
    path: "/game/:id",
    element: <Game/>,
    title: "pack play",
  },
];

export default pagesData;
