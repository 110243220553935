import { Configuration, PublicApi } from "../generated/openapi";

const configuration = new Configuration({
  apiKey: (window as any).Telegram.WebApp.initData,
  basePath: window.location.origin + "/api",
});

const api = new PublicApi(configuration);

export default api;
