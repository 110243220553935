import { BackButton, MainButton, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { ShortTour } from "../../generated/openapi/api";
import api from "../../api";
import { SmileOutlined } from "@ant-design/icons";
import { GameScreenCommonProps } from "./GameCommons";

export const GameTourInfoScreen = (props: GameScreenCommonProps) => {
  const { gameState, goNext } = props;
  const [tourInfo, setTourInfo] = useState<ShortTour>();
  const showPopup = useShowPopup();

  useEffect(() => {
    api.gameGetTour(gameState.id).then((response) => {
      setTourInfo(response.data.tour);
    }).catch(() => {
      showPopup({ message: 'Ошибка при загрузке информации о туре' });
    });
  }, []);

  if (tourInfo == null) {
    return <Row justify="center"><Spin /></Row>;
  }

  return <>
    <Row justify="center">
      <Typography.Title>Тур {gameState.current_tour + 1} {tourInfo.name && `: ${tourInfo.name}`}</Typography.Title>
    </Row>

    <Row justify="center">
      <Typography.Text type="secondary">
        <SmileOutlined /> {tourInfo.authors.length > 0 ? tourInfo.authors.join(", ") : "Авторы не указаны"}
      </Typography.Text>
    </Row>

    <Row>
      <Typography.Title level={4}>Описание тура</Typography.Title>
    </Row>
    <Row>
      <Typography.Paragraph>{tourInfo.description}</Typography.Paragraph>
    </Row>

    <BackButton onClick={() => { }} />
    <MainButton onClick={() => { goNext(); }} text="Перейти к вопросам" />
  </>;
};
